import Layout from 'components/Layout';
import { useBaseTitle } from 'lib/client/useTitle';
import Head from 'next/head';

export default function Custom404() {
	const title = "404 - Siden finnes ikke";

	return <Layout>
		<Head>
			<title>{title}</title>
			<meta property="og:title" content={title} key="title" />
		</Head>
		<h1>Siden finnes ikke</h1>
	</Layout>
}